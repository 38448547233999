import React from 'react'

import { Link } from '@remix-run/react'
import { useTranslation } from 'react-i18next'

export function Layout({
  title,
  subtitle,
  showIconLogo = false,
  children,
}: {
  title?: React.ReactNode
  subtitle?: React.ReactNode
  showIconLogo?: boolean
  children: React.ReactNode
}) {
  const { t } = useTranslation()

  return (
    <div className="flex h-screen min-h-screen flex-col bg-muted lg:py-8">
      <div className="mx-auto flex h-full w-full max-w-[1920px] flex-col items-center lg:flex-row-reverse">
        <aside className="flex max-h-56 min-h-40 w-screen bg-alpha-400 lg:relative lg:mr-6 lg:h-full lg:max-h-none lg:w-full lg:rounded-[2rem]">
          <img
            className="m-auto block w-44 lg:absolute lg:inset-0 lg:w-72"
            src="/images/logo-white.svg"
            alt="Biogroup logo"
          />
        </aside>
        <div className="flex h-full flex-col justify-between">
          <main className="w-full pt-10 lg:my-auto lg:px-[3vw] lg:pt-0 min-[1920px]:px-[3.6rem]">
            <div className="mx-0 flex h-full w-full items-center justify-center lg:min-w-[25rem]">
              <div className="mx-auto max-w-[40rem] px-6">
                {showIconLogo ? (
                  <div className="mb-4 hidden items-center justify-start border-b border-faded py-3 lg:block">
                    <img
                      className="h-10 object-cover"
                      src="/images/logo-icon.svg"
                      alt="Biogroup logo"
                    />
                  </div>
                ) : null}
                {title ? <h1 className="mb-1 text-h4">{title}</h1> : null}
                {subtitle ? (
                  <p className="mb-8 text-md text-muted-foreground">
                    {subtitle}
                  </p>
                ) : null}
                {children}
              </div>
            </div>
          </main>
          <footer className="flex flex-col justify-between px-6 pb-6 lg:items-center lg:pb-0">
            <div className="mt-auto flex flex-wrap items-center justify-center gap-x-4 gap-y-2 pb-4">
              <Link
                prefetch="intent"
                to="/user-agreement"
                className="link text-sm font-normal text-dull-foreground no-underline hover:underline"
              >
                {t('userAgreement')}
              </Link>
              <Link
                prefetch="intent"
                to="/privacy"
                className="link text-sm font-normal text-dull-foreground no-underline hover:underline"
              >
                {t('privacy')}
              </Link>
              <Link
                prefetch="intent"
                to="/cookies"
                className="link text-sm font-normal text-dull-foreground no-underline hover:underline"
              >
                {t('cookies')}
              </Link>
            </div>
            <div className="flex items-center justify-center gap-8 lg:justify-start">
              <p className="text-sm text-dull">
                Copyright &#169; Biogroup {new Date().getFullYear()}
              </p>
            </div>
          </footer>
        </div>
      </div>
    </div>
  )
}
